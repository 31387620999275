<template>
    <div>
        <table class="expand_table">
            <tr v-show="row.risk_description">
                <td class="expand-td-left">现象说明: </td>
                <td class="expand-td-right">{{ row.risk_description }}</td>
            </tr>
            <tr v-show="row.recent_goal">
                <td class="expand-td-left">节点目标: </td>
                <td class="expand-td-right">{{ row.recent_goal }}</td>
            </tr>
            <!--<tr v-show="row.involve_interface">
                <td class="expand-td-left">相关类/专业接口: </td>
                <td class="expand-td-right">{{ row.involve_interface }}</td>
            </tr>
            <tr v-show="row.archive_no">
                <td class="expand-td-left">归档编号: </td>
                <td class="expand-td-right">{{ row.archive_no }}</td>
            </tr>-->
        </table>
    </div>
</template>
<script>
    export default {
        name: "correctListExpand",
        props: {
            row: Object
        }
    }
</script>
<style scoped>
    .wrapper{
        text-align: center;
    }
    .expand_table {
        margin: 0 auto;
        width:80%;
        border-collapse: collapse;
    }
    .expand-td-left{
        padding: 0 20px;
        width:150px;
        text-align: right;
        background-color: inherit;
        border: none;
        color:#888;
    }
    .expand-td-right{
        background-color: inherit;
        border: none;
        font-size: 14px;
    }
</style>