<template>
<div>
  <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh">
    <template v-slot:default="dv_props">
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="ios-pie" />占比图
          </span>
          <div class="echarts-wrapper">
            <div class="single-chart-wrapper">
              <complete-stat
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></complete-stat>
            </div>
            <div class="single-chart-wrapper" v-if="false">
              <res-department
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></res-department>
            </div>
          </div>
        </el-tab-pane>
      </template>
  </data-view>
  <Drawer
      title="查看文件"
      v-model="modalSwitchValue.correct"
      width="900"
      :mask-closable="false"
      placement="left"
      @on-close="closeDrawer('correct')"
    >
      <iframe height="99%" width="100%" :src="pdfUrl + checkUrl"> </iframe>
    </Drawer>
    </div>
</template>
<script>
import dataView from '@/components/Common/DataView.vue'
import correctListExpand from "./CorrectListExpand.vue";
import completeStat from "./Charts/CompleteStat.vue";
import resDepartment from "./Charts/ResDepartment.vue";
let authData = JSON.parse(localStorage.getItem("authData"));


export default {
  name: "correctList",
  props: [],
  components: {
    dataView,
    completeStat,
    resDepartment
  },
  data() {
    return {
      op_name: this.$options.name,
      pdfUrl: "/pdf/web/viewer.html?file=",
      checkUrl: "",
      modalSwitchValue: {
        correct: false,
      },
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        {
          type: "expand",
          width: 30,
          render: (h, params) => {
            return h(correctListExpand, {
              props: {
                row: params.row
              }
            });
          }
        },
        /*{
                        title: '所属城市',
                        key: 'city',
                        width:85,
                        align: "center"
                    },*/
        {
          title: "线路名称",
          key: "line_name",
          width: 85,
          align: "center",
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_id;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "风险点介绍",
          key: "risk_content",
          align: "center",
          minWidth: 120
        },
        {
          title: "评价时间",
          key: "evaluate_year",
          width: 105,
          sortable: true,
          align: "center",
        },
        {
          title: "专业子系统",
          key: "major_system",
          width: 120,
          align: "center",
          filters: [
            {
              label: "供电",
              value: "供电"
            },
            {
              label: "土建",
              value: "土建"
            },
            {
              label: "信号",
              value: "信号"
            },
            {
              label: "车辆",
              value: "车辆"
            },
            {
              label: "通信",
              value: "通信"
            },
            {
              label: "安全管理",
              value: "安全管理"
            },
            {
              label: "机电",
              value: "机电"
            },
            {
              label: "运营组织",
              value: "运营组织"
            },
            {
              label: "消防",
              value: "消防"
            }
          ],
          filterMethod(value, row) {
            return row.major_system.indexOf(value) > -1;
          }
        },
        {
          title: "严重度等级",
          key: "degree_level",
          width: 120,
          align: "center",
          filters: [
            {
              label: "重大的",
              value: "重大的"
            },
            {
              label: "较大的",
              value: "较大的"
            },
            {
              label: "一般的",
              value: "一般的"
            },
            {
              label: "较小的",
              value: "较小的"
            },
          ],
          filterMethod(value, row) {
            return row.degree_level.indexOf(value) > -1;
          }
        },

        /** {
                        title: '专家建议',
                        key: 'expert_proposal',
                        width:150,
                        align: "center"
                    },**/
        {
          title: "责任单位/部门整改措施",
          key: "correct_operation",
          align: "center",
          tooltip: "true",
          minWidth: 120
        },

        {
          title: "责任部门/单位",
          width: 100,
          align: "center",
          key: "res_department"
        },
        // {
        //   title: "分管领导",
        //   width: 85,
        //   align: "center",
        //   key: "res_leader"
        // },
        {
          title: "整改期限",
          key: "limit_date",
          width: 100,
          align: "center",
        },
        {
          title: "进展情况",
          width: 100,
          align: "center",
          tooltip: "true",
          key: "recent_stat_description"
        },
        {
          title: "落实状态",
          width: 90,
          align: "center",
          key: "risk_correct_stat",
        },
        {
          title: "文件",
          align: "center",
          width: 105,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    disabled: !params.row.file_url,
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.checkUrl = encodeURIComponent(
                        this.$fileUrlFac(params.row.file_url,authData.sfileRight)
                      );
                      //this.checkUrl = this.$static_url+'public/ab.pdf'
                      this.handleDrawer("correct");
                      //window.open(this.$static_url+params.row.file_url+'?userToken='+userToken)
                    },
                  },
                },
                params.row.file_url ? "点击查看" : "暂未上传"
              ),
            ]);
          },
        },
      ]
    };
  },
  methods: {
    chartRefresh() {
      this.updateCount++;
    },
    handleDrawer(value) {
      this.modalSwitchValue[value] = true;
    },
    closeDrawer(value) {
      this.modalSwitchValue[value] = false;
    },
  }
};
</script>
<style scoped>
.single-chart-wrapper{
  margin-top:10px;
}
</style>